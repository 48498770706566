import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "v-flex-column" }
const _hoisted_2 = { class: "v-interaction-header-container sp-interaction-details" }
const _hoisted_3 = { class: "v-header-left" }
const _hoisted_4 = { class: "v-interaction-title" }
const _hoisted_5 = { class: "v-header-right" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "v-nourish-instance-detail"
}
const _hoisted_8 = { class: "v-nourish-instance-detail" }
const _hoisted_9 = { key: 0 }

import {
  computed, onBeforeMount, onUnmounted, ref, watch,
} from 'vue';
import { storeToRefs } from 'pinia';

// Components
import {
  useRouter, useRoute, onBeforeRouteUpdate, onBeforeRouteLeave,
} from 'vue-router';
import BaseRightPanel from '@/_shared/components/general/BaseRightPanel.vue';
import {
  ActionsHistory,
  ActionsWontRunBanner,
  AlertBanner,
  BaseCard,
  CancelRecordNote,
  ClosedBy,
  DailyNote,
  DryRunActions,
  InteractionAssignCarers,
  InteractionDocuments,
  InteractionDocumentsSection,
  InteractionLog,
  InteractionPastVersions,
  InteractionStateButtons,
  InteractionTimePicker,
  ParameterWrapper,
  ProtocolActions,
  QuickActions,
  ReadOnlyNotice,
  StateSnooze,
  InteractionTargets,
  PrefillToggle,
  CopyrightText,
  InteractionProtocolContext,
} from '@/timeline/components';
import useUserStore from '@/_shared/store/user';

import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { CloseRightPanelIcon } from '@/_shared/components';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import ClosedAlarmNote from '@/timeline/components/ClosedAlarmNote.vue';
import { isToday } from 'date-fns';
import useMultiSelectRegister from '@/_shared/store/multiSelectRegister';
import useBodyMapRoutes from '@/timeline/helper/useBodyMapRoutes';
import { safeTranslate } from '@/_shared/translations/i18n'; // Fallback to codename
import ConfirmationModal from '@/_shared/components/modals/ConfirmationModal/ConfirmationModal.vue';
import {
  runInteractionActions,
} from '@/timeline/services/interactionsApi';
import useCarePlanStore from '@/careplan/store/carePlanStore';
import ClientType from '@/client_types/types/client_type';
import {
  fetchClientTypes,
} from '@/client_types/services/clientTypesApi';
import { instancesStore } from '@/_shared/store/Instances';
import { NourishInstance } from '@/_shared/types/NourishInstance';
import equal from 'fast-deep-equal/es6';
import useCurrentInteractionStore from './store/currentInteractionStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionsRightPanelMain',
  props: {
  id: {
    type: [Number, String],
    default: 0,
  },
  clientId: {
    type: [Number, String],
    required: false,
  },
  serviceId: {
    type: [Number, String],
  },
},
  setup(__props) {

const router = useRouter();
const route = useRoute();
const { translate, toast, ToastType } = use.helpers();

const props = __props;

const { currentUser } = storeToRefs(useUserStore());
const currentInteractionStore = useCurrentInteractionStore();
const {
  save,
  setNewInteraction,
  setCurrentInteraction,
  changeCurrentInteraction,
  resetInteraction,
  saveInteraction,
  resetAllSignatureParameters,
  undoTypedValues,
} = currentInteractionStore;
const {
  currentInteraction,
  iconUrl,
  isViewOnly,
  isClosed,
  isCancelled,
  interactionLoaded,
  timelineDate,
  isPlannedInFuture,
  hasOldVersionSignatureParam,
  hasActions,
  hasAnyCloseAlarmToDisplay,
  hasChanged,
  wasSavedInBackEnd,
  hasDataPoint,
  needsSummary,
  isNotBulkClose,
  showSignatureResetWarningModal,
} = storeToRefs(currentInteractionStore);
const familyPortal = (route.name as string).includes('portalClient');
const isNotServiceCountRoute = computed(() => !(route.name as string).startsWith('serviceCountList'));
const serviceId = ref(props.serviceId || route.query.serviceId);
const { bulkInteractionIds, bulkClientIds } = storeToRefs(useMultiSelectRegister());
const preventModalShow = ref(false);
const carePlanStore = useCarePlanStore();
const { resetConsentCarePlanData } = carePlanStore;
const clientTypes = ref([] as ClientType[]);

// ***** BulkInteractions *****
watch(() => bulkInteractionIds.value, async () => {
  if (!!bulkInteractionIds.value.length && currentInteraction.value.id && !bulkInteractionIds.value.includes(currentInteraction.value.id)) {
    changeCurrentInteraction();
  }
}, { immediate: true });

const load = async (forceNew = false) => {
  timelineDate.value = route.query.date ? new Date(route.query.date as string) : new Date();
  if (!isToday(timelineDate.value)) notToday.value = true;
  if (forceNew || (serviceId.value && !(props.id || bulkInteractionIds.value.length) && props.clientId)) {
    await setNewInteraction(+(props.clientId as number | string), +(serviceId.value as number | string));
  } else {
    const interactionId = props.id || (bulkInteractionIds.value as number[])[0];
    await setCurrentInteraction(+interactionId);
  }
};
// TODO maybe use beforeRouteUpdate instead of these two watches
watch(() => props.id, () => {
  if (props.id) {
    serviceId.value = null;
    load();
  }
});

watch(() => route.query.serviceId, () => {
  if (route.query.serviceId) {
    serviceId.value = route.query.serviceId;
    load(true);
  }
});

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('timeline'); // TODO WHEN PURE VUE remove;
  await load();
  const orgId = window.currentOrganisationUnit.organisation_id;
  clientTypes.value = await fetchClientTypes(+(orgId as number | string)); // TODO remove when there's a store for client types
});

onUnmounted(() => {
  resetConsentCarePlanData();
  resetInteraction();
});

const loaded = computed(() => interactionLoaded.value);

const hasErrors = computed(() => !!Object.keys(currentInteraction.value?.errors || {}).length);

const { clientStore } = use.store.clients();
const avatarPhoto = computed(() => {
  const clientId = props.clientId || currentInteraction.value?.clientId;
  if (!bulkClientIds.value?.length && clientId) return clientStore.photo(+clientId);
  return undefined;
});
const clientTypeCodename = computed(() => {
  const clientId = props.clientId || currentInteraction.value?.clientId;
  if (!bulkClientIds.value?.length && clientId) return clientStore.clientType(+clientId);
  return undefined;
});
const clientTypeHasLibraries = computed(() => {
  const clientType = clientTypes.value.find((c) => c.codename === clientTypeCodename.value);
  if (!clientType) return true;
  return !!clientType.libraryCodename;
});

const bulkClientIdsCount = computed(() => bulkClientIds.value?.length);

const title = computed(() => {
  // todo get correct name format
  if (isNotServiceCountRoute.value) return isCancelled.value ? 'Cancelled Record - ' : `${currentInteraction.value.name}`;
  if (!bulkClientIdsCount.value) return clientStore.name(currentInteraction.value.clientId);
  const displayLimit = 3;
  const names = clientStore.names((bulkClientIds.value as []).slice(0, displayLimit).map(Number));
  if (bulkClientIdsCount.value <= displayLimit) return names;
  return `${names}${bulkClientIdsCount.value <= displayLimit ? '' : ` ${translate('common.and_other', { count: bulkClientIdsCount.value - displayLimit })}`}`;
});

const nourishInstance = computed(() => {
  if (currentInteraction.value.nourishInstanceId && currentInteraction.value.clientId) {
    return instancesStore.clientInstanceByInstanceId(currentInteraction.value.clientId, currentInteraction.value.nourishInstanceId) as NourishInstance;
  }
  return undefined;
});

const instanceDetail = computed(() => {
  if (nourishInstance.value && nourishInstance.value?.instanceType === 'skin') {
    const woundLocation = safeTranslate(`datasets.${nourishInstance.value?.metadata?.location}`);
    const woundType = safeTranslate(`datasets.${nourishInstance.value?.metadata?.specificType}`);
    return `${woundLocation} - ${woundType}`;
  }
  if (nourishInstance.value && nourishInstance.value?.instanceType === 'event_pws') {
    const specificEvent = safeTranslate(`datasets.${nourishInstance.value?.metadata?.specificType}`);
    const severity = safeTranslate(`datasets.${nourishInstance.value?.metadata?.severity}`);
    return `${specificEvent} - ${severity}`;
  }
  return undefined;
});

// Documents
const documentsVisible = ref(false);
const toggleDocumentsVisible = () => {
  documentsVisible.value = !documentsVisible.value;
};

const logVisible = ref(false);
const pastVersionsVisible = ref(false);
const assignCarersModalVisible = ref(false);
const actionsHistoryVisible = ref(false);
const dryRunActionsVisible = ref(false);
const loading = ref(false);

async function closeInteraction(state: string): Promise<void> {
  if (loading.value) return;
  loading.value = true;
  if (await saveInteraction(state)) {
    preventModalShow.value = true;
    toast(translate('ajax.notices.save_success'), ToastType.Success);
    notifyAngularIfDataPointsPresent();
    routeBackToTimeline();
  }
  loading.value = false;
}

const notifyAngularIfDataPointsPresent = () => {
  window.reloadDataPoints = hasDataPoint.value ? true : null;
};

const isInSaveForLaterMode = ref(false);
const saveForLaterVisible = computed(() => isInSaveForLaterMode.value || isPlannedInFuture.value || hasOldVersionSignatureParam.value);
const notToday = ref(false);

const setSaveForLaterMode = (newValue: boolean) => {
  isInSaveForLaterMode.value = newValue;
};

const cancelNoteVisible = ref(false);
const showCancelNote = () => {
  cancelNoteVisible.value = true;
};
const hideCancelNote = (saveNeeded = false) => {
  cancelNoteVisible.value = false;
  if (saveNeeded) saveAndClose();

  // TODO: reset data points if interaction has any (DataPointParameterUtils)
};

const stateSnoozeVisible = ref(false);
const showStateSnooze = () => {
  stateSnoozeVisible.value = true;
};

const snoozeClosed = async (saveNeeded = false) => {
  if (saveNeeded) {
    await saveAndClose();
  }
  hideStateSnooze();
};

const hideStateSnooze = () => {
  stateSnoozeVisible.value = false;
};

const showInteraction = computed(() => !isCancelled.value
  && !cancelNoteVisible.value
  && !documentsVisible.value
  && !stateSnoozeVisible.value);

const closeRightPanel = () => {
  hideCancelNote();
  hideStateSnooze();
  documentsVisible.value = false;
  routeBackToTimeline();
};

const saveAndClose = async () => {
  await save();
  if (!hasErrors.value) closeRightPanel();
};

function routeBackToTimeline() {
  if (route?.query?.serviceId) { delete route.query.serviceId; }
  if (route.name === 'client.assessments') {
    return;
  }
  switch (route.name) {
    case 'client.assessments.interaction':
    case 'client.assessments.interaction_new':
      router.push({
        name: 'client.assessments',
        query: route.query,
      });
      break;
    case 'client.events.new':
      router.push({ name: 'client.events' });
      break;
    case 'carer.timeline.interaction':
    case 'serviceCountList.timeline.interaction':
    case 'serviceCountList.timeline.bulkClose':
      router.push({
        name: route.name.slice(0, route.name.lastIndexOf('.')),
        query: route.query,
      });
      window.forceAngularToReloadState = true;
      break;

    case 'handovers.interaction':
      router.push({
        name: 'handovers',
        query: route.query,
      });
      window.forceAngularToReloadState = true;
      break;
    default: {
      // path needed for angular router, can be replaced to 'name' once fully switched to vue router
      let name = 'client.timeline';
      if ((route.name as string).startsWith('portalClient.')) {
        name = 'portalClient.timeline';
      } else {
        name = useBodyMapRoutes(route).timelineRouteName.value;
      }
      const { path } = router.resolve({ name });
      router.push({
        path,
        query: route.query,
      });
    }
  }
}

const responsiblePersonIds = computed(() => {
  const ids = Array.from(currentInteraction.value?.responsiblePersonIds || []);
  if (ids.length === 0 && !saveForLaterVisible.value && currentUser.value.role !== 'informal_carer') {
    ids.push(currentUser.value.id);
  }
  return ids;
});

function updateResponsibleIds(newValue: number[]) {
  if (!currentInteraction.value) return;
  currentInteraction.value.responsiblePersonIds = newValue;
}

const showPrefill = computed(() => !isClosed.value && currentInteraction.value?.isPrefillable);
watch([wasSavedInBackEnd, hasChanged], () => {
  // disabling unsaved change check for bulk interactions until service count left panel is in vue
  if (!wasSavedInBackEnd.value && hasChanged.value && isNotBulkClose.value) {
    window.vueUnsavedMessage = translate('common.unsaved_changes');
    return;
  }
  window.vueUnsavedMessage = null;
});

function canContinueWithUnsavedChange() {
  if (window.vueUnsavedMessage) {
    const answer = window.confirm(translate('common.unsaved_changes'));
    if (!answer) return false;
  }
  window.vueUnsavedMessage = null;
  return true;
}

const runActions = async () => {
  await runInteractionActions(currentInteraction.value?.id as number, true);
  routeBackToTimeline();
};

onBeforeRouteUpdate(async (to, from) => {
  if (equal(to.params, from.params) || bulkInteractionIds.value.length) {
    return true;
  }
  if (to.params.instanceId) {
    return instancesStore.interactionRelatedToInstance(+to.params.clientId, +to.params.instanceId, +to.params.id)
      ? canContinueWithUnsavedChange()
      : false;
  }
  return canContinueWithUnsavedChange();
});

onBeforeRouteLeave(() => canContinueWithUnsavedChange());


return (_ctx: any,_cache: any) => {
  return (loaded.value)
    ? (_openBlock(), _createBlock(BaseRightPanel, {
        key: 0,
        class: _normalizeClass({ 'v-bulk-close-form': !_unref(isNotBulkClose) }),
        photo: avatarPhoto.value
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(CloseRightPanelIcon), { onCloseRightPanel: closeRightPanel }),
          (stateSnoozeVisible.value && !documentsVisible.value && !_unref(isCancelled))
            ? (_openBlock(), _createBlock(_unref(StateSnooze), {
                key: 0,
                onCloseStateSnooze: snoozeClosed
              }))
            : _createCommentVNode("", true),
          (documentsVisible.value && !_unref(isCancelled))
            ? (_openBlock(), _createBlock(_unref(InteractionDocuments), {
                key: 1,
                onToggleShowDocuments: toggleDocumentsVisible
              }))
            : _createCommentVNode("", true),
          ((cancelNoteVisible.value || _unref(isCancelled)) && !documentsVisible.value)
            ? (_openBlock(), _createBlock(_unref(CancelRecordNote), {
                key: 2,
                onCloseCancelNote: hideCancelNote
              }))
            : _createCommentVNode("", true),
          (showInteraction.value)
            ? (_openBlock(), _createBlock(_unref(BaseCard), {
                key: 3,
                headerBackground: true,
                padding: false
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        (isNotServiceCountRoute.value)
                          ? (_openBlock(), _createBlock(BaseIcon, {
                              key: 0,
                              name: _unref(iconUrl),
                              size: "2x",
                              noHover: "",
                              compact: "",
                              backgroundColor: "white",
                              foregroundColor: "black",
                              foregroundHoverColor: "white",
                              class: "v-service-icon"
                            }, null, 8, ["name"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", null, _toDisplayString(title.value), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        (!_unref(isCancelled))
                          ? (_openBlock(), _createBlock(_unref(InteractionTimePicker), {
                              key: 0,
                              customDate: notToday.value || saveForLaterVisible.value
                            }, null, 8, ["customDate"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    (nourishInstance.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["v-nourish-instance-row", { 'v-icon-padding': isNotServiceCountRoute.value }]),
                            "data-id": "nourishInstance"
                          }, [
                            (instanceDetail.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(instanceDetail.value), 1))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_8, _toDisplayString(nourishInstance.value.reference), 1)
                          ], 2)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                content: _withCtx(() => [
                  (!_unref(isCancelled))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        (!clientTypeHasLibraries.value && _unref(hasActions))
                          ? (_openBlock(), _createBlock(_unref(ActionsWontRunBanner), { key: 0 }))
                          : _createCommentVNode("", true),
                        (_unref(isNotBulkClose))
                          ? (_openBlock(), _createBlock(_unref(InteractionProtocolContext), {
                              key: 1,
                              protocolContext: _unref(currentInteraction).protocolContextData || undefined
                            }, null, 8, ["protocolContext"]))
                          : _createCommentVNode("", true),
                        (_unref(isNotBulkClose) && _unref(needsSummary))
                          ? (_openBlock(), _createBlock(_unref(InteractionTargets), {
                              key: 2,
                              clientId: _unref(currentInteraction).clientId,
                              providedServiceId: _unref(currentInteraction).providedServiceId,
                              subscribedServiceId: _unref(currentInteraction).subscribedServiceId,
                              serviceId: _unref(currentInteraction).serviceId,
                              dateInterval: (_unref(currentInteraction).startAt || _unref(currentInteraction).finishAt) as string
                            }, null, 8, ["clientId", "providedServiceId", "subscribedServiceId", "serviceId", "dateInterval"]))
                          : _createCommentVNode("", true),
                        (showPrefill.value && _unref(isNotBulkClose))
                          ? (_openBlock(), _createBlock(_unref(PrefillToggle), { key: 3 }))
                          : _createCommentVNode("", true),
                        (_unref(isNotBulkClose))
                          ? (_openBlock(), _createBlock(_unref(InteractionDocumentsSection), {
                              key: 4,
                              onToggleShowDocuments: toggleDocumentsVisible
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_unref(ParameterWrapper), {
                          readOnly: _unref(isViewOnly),
                          inSaveForLaterMode: saveForLaterVisible.value
                        }, null, 8, ["readOnly", "inSaveForLaterMode"]),
                        _createVNode(_unref(DailyNote), {
                          disabled: _unref(isViewOnly),
                          "show-handover": !_unref(isViewOnly)
                        }, null, 8, ["disabled", "show-handover"]),
                        (_unref(isNotBulkClose))
                          ? (_openBlock(), _createBlock(_unref(ProtocolActions), {
                              key: 5,
                              isEditing: saveForLaterVisible.value
                            }, null, 8, ["isEditing"]))
                          : _createCommentVNode("", true),
                        (_unref(hasAnyCloseAlarmToDisplay))
                          ? (_openBlock(), _createBlock(ClosedAlarmNote, { key: 6 }))
                          : _createCommentVNode("", true),
                        (!_unref(familyPortal))
                          ? (_openBlock(), _createBlock(_unref(QuickActions), {
                              key: 7,
                              onCancelInteraction: showCancelNote,
                              onSnoozeInteraction: showStateSnooze,
                              onShowLog: _cache[0] || (_cache[0] = ($event: any) => (logVisible.value = true)),
                              onShowAssignCarersModal: _cache[1] || (_cache[1] = ($event: any) => (assignCarersModalVisible.value = true)),
                              onShowPastVersions: _cache[2] || (_cache[2] = ($event: any) => (pastVersionsVisible.value = true)),
                              onShowActionsHistory: _cache[3] || (_cache[3] = ($event: any) => (actionsHistoryVisible.value = true)),
                              onSetSaveForLaterMode: setSaveForLaterMode,
                              onDryRunActions: _cache[4] || (_cache[4] = ($event: any) => (dryRunActionsVisible.value = true))
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_unref(ClosedBy), {
                          responsiblePersonIds: responsiblePersonIds.value,
                          isEditing: saveForLaterVisible.value,
                          closerId: _unref(currentInteraction).closerId,
                          lastEditedId: _unref(currentInteraction).lastEditedBy
                        }, null, 8, ["responsiblePersonIds", "isEditing", "closerId", "lastEditedId"]),
                        _createVNode(_unref(ReadOnlyNotice), { readOnly: _unref(isViewOnly) }, null, 8, ["readOnly"]),
                        (hasErrors.value)
                          ? (_openBlock(), _createBlock(_unref(AlertBanner), {
                              key: 8,
                              "interaction-errors": _unref(currentInteraction).errors
                            }, null, 8, ["interaction-errors"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(InteractionStateButtons), {
                    isEditing: _unref(isCancelled) || saveForLaterVisible.value,
                    onSaveInteraction: closeInteraction
                  }, null, 8, ["isEditing"]),
                  _createVNode(_unref(CopyrightText), {
                    text: _unref(currentInteraction).copyrightText
                  }, null, 8, ["text"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (logVisible.value)
            ? (_openBlock(), _createBlock(_unref(InteractionLog), {
                key: 4,
                title: _unref(currentInteraction).name,
                iconUrl: _unref(iconUrl),
                serviceId: _unref(currentInteraction).serviceId,
                clientId: _unref(currentInteraction).clientId,
                onCloseLog: _cache[5] || (_cache[5] = ($event: any) => (logVisible.value = false))
              }, null, 8, ["title", "iconUrl", "serviceId", "clientId"]))
            : _createCommentVNode("", true),
          (pastVersionsVisible.value && _unref(currentInteraction).id)
            ? (_openBlock(), _createBlock(_unref(InteractionPastVersions), {
                key: 5,
                iconUrl: _unref(iconUrl),
                interactionId: _unref(currentInteraction).id,
                clientId: _unref(currentInteraction).clientId,
                needsStartAt: !!_unref(currentInteraction).needsStartAt,
                hideEmptyParameters: !!_unref(currentInteraction).hideEmptyParameters,
                onClosePastVersions: _cache[6] || (_cache[6] = ($event: any) => (pastVersionsVisible.value = false))
              }, null, 8, ["iconUrl", "interactionId", "clientId", "needsStartAt", "hideEmptyParameters"]))
            : _createCommentVNode("", true),
          _createVNode(_unref(InteractionAssignCarers), {
            onCloseAssignCarers: _cache[7] || (_cache[7] = ($event: any) => (assignCarersModalVisible.value = false)),
            interaction: _unref(currentInteraction),
            selectedEntries: responsiblePersonIds.value,
            onUpdateResponsibleIds: updateResponsibleIds,
            showModal: assignCarersModalVisible.value,
            iconUrl: _unref(iconUrl)
          }, null, 8, ["interaction", "selectedEntries", "showModal", "iconUrl"]),
          _createVNode(_unref(ActionsHistory), {
            onClose: _cache[8] || (_cache[8] = ($event: any) => (actionsHistoryVisible.value = false)),
            showModal: actionsHistoryVisible.value
          }, null, 8, ["showModal"]),
          (_unref(showSignatureResetWarningModal) && !preventModalShow.value)
            ? (_openBlock(), _createBlock(ConfirmationModal, {
                key: 6,
                onCloseModal: _cache[9] || (_cache[9] = ($event: any) => (_unref(undoTypedValues)())),
                onConfirm: _cache[10] || (_cache[10] = ($event: any) => (_unref(resetAllSignatureParameters)())),
                firstRowText: _unref(translate)('timeline.parameters.signature.signatureResetWarning1', { interaction_name: _unref(currentInteraction).name }),
                secondRowText: _unref(translate)('timeline.parameters.signature.signatureResetWarning2'),
                thirdRowText: _unref(translate)('timeline.parameters.signature.signatureResetWarning3', { interaction_name: _unref(currentInteraction).name  }),
                titleText: _unref(translate)('timeline.parameters.signature.signatureResetWarningTitle')
              }, null, 8, ["firstRowText", "secondRowText", "thirdRowText", "titleText"]))
            : _createCommentVNode("", true),
          (dryRunActionsVisible.value && _unref(currentInteraction).state ==='closed')
            ? (_openBlock(), _createBlock(_unref(DryRunActions), {
                key: 7,
                onCloseDryRunActions: _cache[11] || (_cache[11] = ($event: any) => (dryRunActionsVisible.value = false)),
                onRunActions: runActions,
                showModal: dryRunActionsVisible.value,
                "interaction-id": _unref(currentInteraction).id!,
                "service-codename": _unref(currentInteraction).serviceCodename!
              }, null, 8, ["showModal", "interaction-id", "service-codename"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class", "photo"]))
    : _createCommentVNode("", true)
}
}

})